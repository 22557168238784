import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Checkup from "../../components/checkup"
import { Link } from "gatsby"
import ecanta from "../../assets/img/ecanta-basari-hikayesi.png"
import customer from "../../assets/img/selim-goksin.png"
import sorunlar from "../../assets/img/ecanta-sorunlar.png"
import google from "../../assets/img/ecanta-google-ads.png"
import blackFriday from "../../assets/img/ecanta-black-friday.png"
import anahtarKelimeler from "../../assets/img/ecanta-anahtar-kelimeler.png"
import seoIcon from "../../assets/img/seo-icon.png"
import dijitalStrateji from "../../assets/img/dijital-strateji-icon.png"
import googleAds from "../../assets/img/google-ads-logo.png"
import trafikIcon from "../../assets/img/trafik-icon.png"
import organikTrafik from "../../assets/img/ecanta-organik-trafik.png"
import cozum from "../../assets/img/ecanta-cozumler.png"
import OtherCaseStudy from "../../components/other-case"
import serife from "../../assets/img/teams/20.jpg"
import eelbeyglu from "../../assets/img/teams/11.jpg"
import melisa from "../../assets/img/teams/18.jpg"



const ReferancesDetail = () => (
    <Layout>
        <SEO title="eÇanta Başarı Hikayesi" />
        <div className="referances-detail py60 container">
            <div className="col-md-6 detail-left">
                <b>BAŞARI HİKAYESİ</b>
                <h1>eÇanta</h1>
                <p style={{ maxWidth: 300 }}>ecanta.com.tr 2002 yılında satışa başlayan, yıllar geçtikçe gelişen ve şu an dünyanın en büyük çanta markalarının ürünlerini Türkiye’de kullanıcıya rahatlıkla ulaştıran bir e-ticaret websitesi.</p>
                <p style={{ maxWidth: 300, fontSize: 15 }}>2017-2020 yılları arasında çalışmaya başlanarak ciddi bir ivme kazandırılmıştır.</p>
                <a className="svgLink" target="_blank" href="#"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 48 48">
                    <g id="Group_11" data-name="Group 11" transform="translate(-432 -2296)">
                        <g id="Ellipse_1" data-name="Ellipse 1" transform="translate(432 2296)" fill="none" stroke="#000" stroke-width="3">
                            <circle cx="24" cy="24" r="24" stroke="none" />
                            <circle cx="24" cy="24" r="22.5" fill="none" />
                        </g>
                        <path id="Path_80" data-name="Path 80" d="M422.384,556.893l-2.14,2.154,7.736,7.846-7.736,7.846,2.14,2.154,9.86-10Z" transform="translate(30.756 1753.107)" />
                    </g>
                </svg>
                    <span>Devamını Gör</span></a>
                <img className="pakmaya-headimg" style={{ position: 'absolute', height: 360, right: -90, top: 0, zIndex: -1 }} src={ecanta} />
            </div>
            <div className="col-md-6 referances-boxs">
                <div className="boxs" style={{ marginBottom: '0' }}>
                    <div className="box">
                        <span>+28k</span>
                        <p>Organik trafik artışı</p>
                    </div>
                    <div className="box">
                        <span>+%26</span>
                        <p>Reklam maliyetinin düşürülmesi</p>
                    </div>

                </div>
                <div className="provided">
                    <h3 style={{ margin: '10px 0' }}>Verilen Hizmetler</h3>
                    <div className="provided-box">
                        <div className="box">
                            <Link to="/eposta-pazarlama/"></Link>
                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 86 86">
                                <g id="Icons-_-Communication-_-Mail-heart" data-name="Icons-/-Communication-/-Mail-heart" transform="translate(0.169 -0.307)">
                                    <rect id="bound" width="86" height="86" transform="translate(-0.169 0.307)" fill="none" />
                                    <path id="Combined-Shape" d="M8.569,2H51.4a3.569,3.569,0,0,1,3.569,3.569V41.262A3.569,3.569,0,0,1,51.4,44.831H8.569A3.569,3.569,0,0,1,5,41.262V5.569A3.569,3.569,0,0,1,8.569,2Zm27.84,7.138c-2.3,0-4.985,2.605-6.425,4.283-1.439-1.678-4.127-4.283-6.425-4.283-4.068,0-6.425,3.173-6.425,7.21q0,6.71,12.849,14.2,12.849-7.5,12.849-13.92C42.834,12.6,40.477,9.138,36.409,9.138Z" transform="translate(13.236 5.138)" fill="#111" fill-rule="evenodd" opacity="0.3" />
                                    <path id="Combined-Shape-2" data-name="Combined-Shape" d="M5.829,6.816,35.123,27.972,64.417,6.816a1.785,1.785,0,0,1,2.829,1.447V44.034a7.138,7.138,0,0,1-7.138,7.138H10.138A7.138,7.138,0,0,1,3,44.034V8.263A1.785,1.785,0,0,1,5.829,6.816Z" transform="translate(8.097 16.643)" fill="#111" fill-rule="evenodd" />
                                </g>
                            </svg>
                            Eposta Pazarlama
                        </div>
                        <div className="box">
                            <Link to="/ui-ux-tasarim/"></Link>
                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 76 76">
                                <g id="Icons-_-Design-_-Sketch" data-name="Icons-/-Design-/-Sketch" transform="translate(0.015 -0.307)">
                                    <rect id="bound" width="76" height="76" transform="translate(-0.015 0.307)" fill="none" />
                                    <path id="Path-48" d="M13.728,3H58.276L71,18.91H1Z" transform="translate(2.182 6.546)" fill-rule="evenodd" opacity="0.3" />
                                    <path id="Path-48-Copy" d="M71,8,36,46.184,1,8Z" transform="translate(2.182 17.456)" fill-rule="evenodd" />
                                </g>
                            </svg>
                            UI&UX Tasarım
                        </div>
                    </div>
                </div>
                <div className="project-details">
                    <h3 style={{ margin: '10px 0' }}>Künye</h3>
                    <div className="project-details_list">
                        <ul>
                            <li>Marka Yöneticisi:</li>
                            <li>Dijital Ads:</li>
                            <li>SEO:</li>
                        </ul>
                        <ul>
                            <li><img src={melisa} />Melisa Akınsal</li>
                            <li><img src={serife} alt="Şerife Aytekin" />Şerife Aytekin</li>
                            <li><img src={eelbeyglu}  alt="Alaattin Emre Elbeyoğlu" />Emre Elbeyoğlu</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div className="container">

            <div className="content-section content row" >
                <div className="col-md-6">
                    <img className="pr100" src={sorunlar} />
                </div>
                <div className="col-md-6">
                    <span className="small">Başarı Hikayesi</span>
                    <h3>Sorunlar</h3>
                    <p style={{ maxWidth: 429 }} className="small">- ecanta.com.tr’nin en büyük sorunu dünyaca ünlü markaları uygun fiyatlara satabilmesinden dolayı insanların güvenip satın almaması idi. Çoğu insan sahte olabileceğinden şüphe ettiği için dönüşüm oranları çok düşüktü.</p>
                    <p style={{ maxWidth: 429 }} className="small">- ecanta.com.tr hazır bir eticaret altyapı çözümü kullanıyordu ve SEO uyumlu değildi. Bu yüzden ürün çeşitliliğine rağmen organik trafik alamıyordu.
                    </p>
                    <p style={{ maxWidth: 429 }} className="small">- Reklamlar aynı şekilde marka güvenirliliğini yansıtmadığı için dönüşüm oranları düşüktü.
                    </p>
                </div>
            </div>

            <div className="content-section content row py60" id="eCantaCozum">
                <div className="col-md-6">
                    <span className="small">Başarı Hikayesi</span>
                    <h3>Çözüm</h3>
                    <p style={{ maxWidth: 429 }}>Çözüm olarak öncelikle markanın güvenilirliği ve bilinirliliğini arttırmaya yönelik bir iletişim stratejisi başlattık.</p>
                    <p style={{ maxWidth: 429 }} className="small">Reklam, SEO ve sosyal medya kanallarında ecanta.com.tr’nin aslında Tuğça Çanta’nın olduğunun 29 yıldır bu işi yaptığını ve sattığı markaların resmi distribütörü olduğunu gösteren bir iletişim yapıldı.</p>
                </div>
                <div className="col-md-6">
                    <img src={cozum} />
                </div>
            </div>
        </div>
        <div className="testimonial-customer"  style={{ padding: '100px 13px', backgroundColor: '#F8EEE9' }}>
            <p className="pakmaya-center_text" style={{ fontSize: 24, maxWidth: 700, margin: '0 auto 20px' }}>“ eçanta olarak sattığımız onlarca markanın Google’da öne çıkması konusunda Flat Art çok hızlı yol almamıza yardımcı olarak satışlarımızın hızlı bir ivme ile yükselmesini sağladı. ”</p>
            <div className="author">
                <div>
                    <img src={customer}></img>
                </div>
                <div className="author-name">
                    Selim Göksin<br />
                    <span>CEO</span>
                </div>
            </div>
        </div>

        <div className="container">
            <div className="text-center content py60">
                <span className="small">Başarı Hikayesi</span>
                <h2 style={{ margin: '7px auto 0', maxWidth: 700 }}>Son 1 yılda aylık 1.200 ziyaretçiden, 28.000 organik ziyaretçiye ulaştık.</h2>
            </div>

            <div className="content-section content row" id="ecantaTrafik">
                <div className="col-md-6">
                    <div className="inline_img-text">
                        <img style={{ width: 50 }} src={trafikIcon} alt="organik trafik grafik göstergesi" />
                        <div>
                            <p className="small">1 Yılda 30 Kat Büyüme</p>
                            <p>28.000 Organik</p>
                        </div>
                    </div>
                    <div className="inline_img-text">
                        <img style={{ width: 50 }} src={seoIcon} alt="organik trafik grafik göstergesi" />
                        <div>
                            <p className="small">Aramalarda Yükselme</p>
                            <p>2. Sıraya Yerleşti</p>
                        </div>
                    </div>

                    <p style={{ maxWidth: 429 }}>Yapılan on-page ve off-page seo çalışmaları neticesinde 1 yıl gibi bir süre içerisinde organik trafik yaklaşık 30 katına çıkarak 28.000 aylık organik ziyaretçi seviyelerine ulaşmıştır.</p>
                    <p style={{ maxWidth: 429 }} className="small"> Trafiğin yanı sıra sahip olduğu markalarda bulunan marka aramalarında markadan hemen sonra görünecek şekilde 2. pozisyonda yerini almıştır.
                    </p>
                </div>
                <div className="col-md-6">
                    <img src={organikTrafik} />
                </div>
            </div>

            <div className="content-section content row py60" >
                <div className="col-md-6">
                    <img className="pr100" src={anahtarKelimeler} />
                </div>
                <div className="col-md-6">
                    <span className="small">Başarı Hikayesi</span>
                    <h3>Anahtar Kelimeler</h3>
                    <p style={{ maxWidth: 429 }} className="small">- ecanta.com.tr’nin en büyük sorunu dünyaca ünlü markaları uygun fiyatlara satabilmesinden dolayı insanların güvenip satın almaması idi. Çoğu insan sahte olabileceğinden şüphe ettiği için dönüşüm oranları çok düşüktü.</p>
                    <p style={{ maxWidth: 429 }} className="small">- ecanta.com.tr hazır bir eticaret altyapı çözümü kullanıyordu ve SEO uyumlu değildi. Bu yüzden ürün çeşitliliğine rağmen organik trafik alamıyordu.
                    </p>
                    <p style={{ maxWidth: 429 }} className="small">- Reklamlar aynı şekilde marka güvenirliliğini yansıtmadığı için dönüşüm oranları düşüktü.
                    </p>
                </div>
            </div>

            <div className="content-section content row" id="ecantaGoogleAds">
                <div className="col-md-6">
                    <img src={googleAds} alt="google ads" style={{ height: 38, width: 'auto', display: 'block', marginBottom: 20 }} />
                    <span className="small">Başarı Hikayesi</span>
                    <h3>Google Ads scripts ile doğru zamanda doğru tıklama, doğru CPC maliyeti.</h3>
                    <p style={{ maxWidth: 429 }}>Gerçekleştirdiğimiz Google reklamlarında shopping, search ads ve GDN mecralarında fazla marka ve ürün yönetebimek için Google Ads script’leri kullandık.</p>
                    <p style={{ maxWidth: 429 }} className="small">Böylelikle belirlediğimiz algoritma doğrultusunda reklam paneli 7/24  temel bazı metriklere göre optimizasyon çalışmalarını gerçekleştirdi. </p>
                </div>
                <div className="col-md-6">
                    <img src={google} />
                </div>
            </div>

            <div className="text-center content py60">
                <span className="small">Başarı Hikayesi</span>
                <h2 style={{ margin: '7px auto 0', maxWidth: 700 }}>Black Friday dijital stratejimiz ile %450 trafik artışı, %650 dönüşüm oranı artışı.</h2>
                <p style={{ margin: '20px auto 20px', maxWidth: 750 }}>Gerçekleştirdiğimiz Google reklamlarında shopping, search ads ve GDN mecralarında fazla marka ve ürün yönetebimek için Google Ads script’leri kullandık.</p>
                <p style={{ margin: '0 auto', maxWidth: 500 }} className="small">Böylelikle belirlediğimiz algoritma doğrultusunda reklam paneli 7/24 temel bazı metriklere göre optimizasyon çalışmalarını gerçekleştirdi. </p>
            </div>


            <div className="content-section content row" id="ecantaBlackFriday" >
                <div className="col-md-6">
                    <div className="inline_img-text">
                        <img style={{ width: 50 }} src={trafikIcon} alt="organik trafik grafik göstergesi" />
                        <div>
                            <p className="small">Dijital Stratejimiz ile</p>
                            <p>%450 Trafik Artışı</p>
                        </div>
                    </div>
                    <div className="inline_img-text">
                        <img style={{ width: 50 }} src={dijitalStrateji} alt="organik trafik grafik göstergesi" />
                        <div>
                            <p className="small">Dijital Stratejimiz ile</p>
                            <p>%650 Dönüşüm</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <img src={blackFriday} />
                </div>
            </div>
        </div>

        <OtherCaseStudy />

        <Checkup />
    </Layout >
)

export default ReferancesDetail
